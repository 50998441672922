function scrollTo(targetY, {onDone}) {
  let lastTime = performance.now();
  let currentY = window.scrollY;
  let acc = 0.01;

  const performScroll = (time) => {
    const dtInS = (time - lastTime) / 1000;
    lastTime = time;
    if (dtInS > 0) {
      const maxVel = 10000 * dtInS * acc;
      const diff = Math.max(-maxVel, Math.min(targetY - currentY, maxVel));
      acc += dtInS * 2;
      currentY += diff;
      window.scrollTo(window.scrollX, currentY);
    }
    if (currentY !== targetY) {
      requestAnimationFrame(performScroll);
    } else if (onDone) {
      onDone();
    }
  };

  requestAnimationFrame(performScroll);
}

export function scrollToTop({offset = 0, onDone} = {}) {
  scrollTo(offset, {onDone});
}

export function scrollToNode(node, {offset = 0, onDone} = {}) {
  if (!node) return;
  const rect = node.getBoundingClientRect();
  const targetY = rect.top + window.scrollY + offset;
  scrollTo(targetY, {onDone});
}
