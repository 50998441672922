import React from "react";
import {graphql, navigate} from "gatsby";
import XText from "../ui/XText";
import MainLayout from "./MainLayout";
import Strip from "../ui/Strip";
import XCol from "../ui/XCol";
import BlockContent from "../components/BlockContent";
import {Textarea} from "../ui/Input";
import PlainButton from "../ui/PlainButton";
import XRow from "../ui/XRow";
import XPush from "../ui/XPush";
import ROUTES from "../routes";
import {scrollToNode} from "../utils/scroll";
import {useSaveTaskData, useTaskData} from "../models/session";
import PhotoInput from "../components/PhotoInput";

const TextInput = ({value, isLoading, onChange}) => {
  const handleBlur = (e) => onChange(e.target.value);

  return (
    <XCol sp={1}>
      <XText preset="uppercase" color="green600" size="sm">
        Antwort
      </XText>
      <Textarea minRows={3} defaultValue={value || ""} readOnly={isLoading} onBlur={handleBlur} />
    </XCol>
  );
};

const AudioInput = () => (
  <div>
    <button>Audio aufnehmen</button>
  </div>
);

const inputByType = {
  text: TextInput,
  image: PhotoInput,
  audio: AudioInput,
};

const Input = ({task, station, taskNumber, chapterNumber}) => {
  const inputType = task.inputType || "text";
  const Comp = inputByType[inputType];

  const [data, isLoading] = useTaskData(task._key, inputType);
  const saveHandler = useSaveTaskData(task._key, inputType);

  const handleChange = (value) =>
    saveHandler({
      value,
      taskNumber,
      chapterNumber,
      stationNumber: station.order,
      stationTitle: station.title,
    });

  return <Comp value={data && data.value} onChange={handleChange} isLoading={isLoading} />;
};

const Task = ({task, taskNumber, chapterNumber, station}) => {
  // const Input = inputByType[task.inputType] || TextInput;

  return (
    <XCol sp={2}>
      <XCol px={3}>
        <BlockContent content={task._rawQuestion} />
      </XCol>
      <XCol bg="green100" px={3} py={5}>
        <Input
          station={station}
          task={task}
          chapterNumber={chapterNumber}
          taskNumber={taskNumber}
          key={task._key}
        />
      </XCol>
    </XCol>
  );
};

const Chapter = React.forwardRef(({station, chapter, number}, ref) => (
  <XCol bg="white" elevation="md" rounded="md" cropContent ref={ref}>
    <XCol sp={2} bg="green100" px={3} py={3}>
      <XText preset="bold" color="green800" size="lg">
        {number}. {chapter.title}
      </XText>
      <BlockContent content={chapter._rawContent} />
    </XCol>
    <XCol sp={7} pt={4}>
      {chapter.tasks.map((task, idx) => (
        <Task station={station} task={task} chapterNumber={number} taskNumber={idx + 1} key={idx} />
      ))}
    </XCol>
  </XCol>
));

const useChapter = ({location}) => {
  const getChapter = () => {
    const {hash} = location;
    if (!hash) return 0;
    const m = hash.match(/c(\d+)/);
    return m ? parseInt(m[1]) - 1 : 0;
  };
  const setChapter = (num) => {
    navigate(`${location.pathname}#c${num + 1}`);
  };
  return [getChapter(), setChapter];
};

const Station = ({data, ...rest}) => {
  const {sanityStation: station} = data;
  const [currChapter, setCurrChapter] = useChapter({location: rest.location, navigate});

  const prevChapterRef = React.useRef(currChapter);
  const boxRef = React.useRef(null);
  React.useEffect(() => {
    if (currChapter !== prevChapterRef.current) {
      if (boxRef.current) scrollToNode(boxRef.current, {offset: -5});
    }
    prevChapterRef.current = currChapter;
  }, [currChapter]);

  const hasNext = currChapter < station.chapters.length - 1;

  return (
    <MainLayout {...rest}>
      <Strip size="sm" fillParent sp={6}>
        <XRow align="end" sp={2}>
          <XCol sp={1}>
            <XText preset="uppercase" color="green600" size={1}>
              Station {station.order}
            </XText>
            <XText preset="bold" color="green800" size="xl">
              {station.title}
            </XText>
          </XCol>
          <XPush />
          {station.locationUrl && (
            <PlainButton size="md" color="white" href={station.locationUrl} target="_blank">
              {station.location}
            </PlainButton>
          )}
        </XRow>
        <Chapter
          chapter={station.chapters[currChapter]}
          number={currChapter + 1}
          ref={boxRef}
          station={station}
        />
        <XRow>
          {currChapter > 0 && (
            <PlainButton color="white" onClick={() => setCurrChapter(currChapter - 1)}>
              Zurück
            </PlainButton>
          )}
          <XPush />
          {hasNext ? (
            <PlainButton color="white" onClick={() => setCurrChapter(currChapter + 1)}>
              Weiter
            </PlainButton>
          ) : (
            <PlainButton to={ROUTES.home}>Speichern</PlainButton>
          )}
        </XRow>
        {hasNext && (
          <XCol align="center">
            <PlainButton to={ROUTES.home}>Stationsübersicht</PlainButton>
          </XCol>
        )}
      </Strip>
    </MainLayout>
  );
};

export default Station;

export const query = graphql`
  query StationQuery($id: String!) {
    sanityStation(id: {eq: $id}) {
      order
      title
      location
      locationUrl
      slug {
        current
      }
      bgImg {
        asset {
          url
        }
      }
      chapters {
        title
        _rawContent
        tasks {
          _key
          _rawQuestion
          inputType
        }
      }
    }
  }
`;
